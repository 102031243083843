import config from './config';
import { openFile } from './util';
import IconShipping from '@material-ui/icons/LocalShipping';
import IconImport from '@material-ui/icons/Publish';
import Papa from 'papaparse';
import React, { cloneElement } from 'react';
import {
  Button,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceInput,
  sanitizeListRestProps,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

const ListActions = (props: any) => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <Button
        onClick={() =>
          openFile('.csv', async (file: File) =>
            Papa.parse(file, {
              encoding: 'gbk',
              complete: async csv => {
                const data = csv.data;
                data.shift();

                const token = localStorage.getItem('auth');

                const request = new Request(`${config.api}/GiveawayItems`, {
                  method: 'PUT',
                  body: JSON.stringify(
                    data
                      .filter((r: any) => r.length > 10)
                      .map((r: any) => {
                        return {
                          order_serial: r[10].replace(/\D/g, ''),
                          name: r[6],
                          phone: r[7],
                          address: r[8],
                        };
                      })
                  ),
                  headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  }),
                });
                try {
                  const response = await fetch(request);
                  const body = await response.json();
                  if (response.status < 200 || response.status >= 300) {
                    throw new Error(body.message);
                  }
                  refresh();
                  notify('更新信息成功');
                } catch (e) {
                  notify(e.message, 'error');
                }
              },
            })
          )
        }
        label='导入'>
        <IconImport />
      </Button>
      <Button
        onClick={() =>
          openFile('.csv', async (file: File) =>
            Papa.parse(file, {
              encoding: 'gbk',
              complete: async csv => {
                const data = csv.data;
                data.shift();

                const token = localStorage.getItem('auth');

                const request = new Request(`${config.api}/GiveawayItems`, {
                  method: 'PATCH',
                  body: JSON.stringify(
                    data
                      .filter((r: any) => r.length > 8)
                      .map((r: any) => ({
                        id: parseInt(r[0]),
                        delivery_company: r[7],
                        delivery_no: r[8],
                      }))
                  ),
                  headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  }),
                });
                try {
                  const response = await fetch(request);
                  const body = await response.json();
                  if (response.status < 200 || response.status >= 300) {
                    throw new Error(body.message);
                  }
                  refresh();
                  notify('更新信息成功');
                } catch (e) {
                  notify(e.message, 'error');
                }
              },
            })
          )
        }
        label='发货'>
        <IconShipping />
      </Button>
    </TopToolbar>
  );
};

const ItemFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label='搜索' source='q' alwaysOn />
    <ReferenceInput
      source='shop_id'
      reference='Shops'
      label='店铺'
      allowEmpty
      alwaysOn
      emptyValue={0}>
      <SelectInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput
      source='good_id'
      reference='Goods'
      label='赠品'
      allowEmpty
      alwaysOn
      emptyValue={0}>
      <SelectInput optionText='name' />
    </ReferenceInput>
  </Filter>
);

const ItemPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 200]} {...props} />
);

export const GiveawayItemList = (props: any) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={<ItemFilter />}
      filterDefaultValues={{ shop_id: 0, good_id: 0 }}
      perPage={25}
      pagination={<ItemPagination />}>
      <Datagrid>
        <TextField source='shops_name' label='店铺' />
        <TextField source='order_serial' label='订单号' />
        <DateField source='datetime' label='时间' />
        <TextField source='goods_name' label='赠品' />
        <NumberField source='count' label='数量' />
        <TextField source='comment' label='备注' />
        <TextField source='name' label='客户' />
        <TextField source='phone' label='电话' />
        <TextField source='address' label='地址' />
        <TextField source='delivery_company' label='快递' />
        <TextField source='delivery_no' label='单号' />
        <TextField source='users_name' label='操作员' />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const GiveawayItemEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source='shops_name' label='店铺' fullWidth />
      <TextField source='order_serial' label='订单号' fullWidth />
      <DateField source='datetime' label='时间' fullWidth />
      <ReferenceInput
        source='good_id'
        reference='Goods'
        label='赠品'
        fullWidth
        isRequired>
        <SelectInput optionText='name' />
      </ReferenceInput>
      <NumberInput source='count' label='数量' fullWidth isRequired />
      <TextInput source='comment' label='备注' fullWidth />
      <TextInput source='name' label='客户' fullWidth />
      <TextInput source='phone' label='电话' fullWidth />
      <TextInput source='address' label='地址' fullWidth />
      <TextInput source='delivery_company' label='快递' fullWidth />
      <TextInput source='delivery_no' label='单号' fullWidth />
    </SimpleForm>
  </Edit>
);

export const GiveawayItemCreate = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('改动已保存');
    redirect('/GiveawayItems');
    refresh();
  };

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <ReferenceInput
          source='shop_id'
          reference='Shops'
          label='店铺'
          fullWidth
          isRequired>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <TextInput source='order_serial' label='订单号' fullWidth isRequired />
        <ReferenceInput
          source='good_id'
          reference='Goods'
          label='赠品'
          fullWidth
          isRequired>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <NumberInput source='count' label='数量' fullWidth isRequired />
        <TextInput source='comment' label='备注' fullWidth />
        <TextInput source='name' label='客户' fullWidth />
        <TextInput source='phone' label='电话' fullWidth />
        <TextInput source='address' label='地址' fullWidth />
        <TextInput source='delivery_company' label='快递' fullWidth />
        <TextInput source='delivery_no' label='单号' fullWidth />
      </SimpleForm>
    </Create>
  );
};
