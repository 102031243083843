import config from './config';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { downloadCSV, useNotify, useRedirect, useRefresh } from 'react-admin';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    height: '3.6em',
  },
}));

export default function Export() {
  const today = new Date().toISOString().substring(0, 10);
  //const start = new Date(new Date().setDate(end.getDate() - 30));

  const classes = useStyles();

  const notify = useNotify();

  const [values, setValues] = React.useState({
    start: today,
    end: today,
    username: '',
    role: 1,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const timestamp = (date: string) => {
    var datum = Date.parse(date);
    return datum / 1000;
  };

  const items = async (e: any) => {
    e.preventDefault();

    const token = localStorage.getItem('auth');

    const request = new Request(`${config.api}/GiveawayItems:export`, {
      method: 'POST',
      body: JSON.stringify({
        start: timestamp(`${values.start} 00:00`),
        end: timestamp(`${values.end} 23:59:59`),
      }),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    });

    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        const body = await response.json();
        throw new Error(body.message);
      }
      downloadCSV(
        await response.arrayBuffer(),
        `发货表_${values.start}_${values.end}`
      );
    } catch (e) {
      notify(e.message, 'error');
    }
  };

  const accountant = async (e: any) => {
    e.preventDefault();

    const token = localStorage.getItem('auth');

    const request = new Request(
      `${config.api}/GiveawayItems:export_accountant`,
      {
        method: 'POST',
        body: JSON.stringify({
          start: timestamp(values.start),
          end: timestamp(values.end),
        }),
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }),
      }
    );

    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        const body = await response.json();
        throw new Error(body.message);
      }
      downloadCSV(
        await response.arrayBuffer(),
        `财务表_${values.start}_${values.end}`
      );
    } catch (e) {
      notify(e.message, 'error');
    }
  };

  const enable = async (e: any) => {
    e.preventDefault();

    const token = localStorage.getItem('auth');

    const request = new Request(`${config.api}/auth/enable`, {
      method: 'POST',
      body: JSON.stringify({
        username: values.username,
        role: values.role,
      }),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    });

    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        const body = await response.json();
        throw new Error(body.message);
      }

      notify('激活成功', 'info');
    } catch (e) {
      notify(e.message, 'error');
    }
  };

  return (
    <Container component='main'>
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    name='start'
                    variant='outlined'
                    required
                    fullWidth
                    id='start'
                    label='开始日期'
                    type='date'
                    value={values.start}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='end'
                    variant='outlined'
                    required
                    fullWidth
                    id='end'
                    label='结束日期'
                    type='date'
                    value={values.end}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type='submit'
                    size='large'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.button}
                    onClick={items}>
                    导出发货表
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type='submit'
                    size='large'
                    fullWidth
                    variant='contained'
                    color='secondary'
                    className={classes.button}
                    onClick={accountant}>
                    导出财务表
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12}>
            <form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    name='username'
                    variant='outlined'
                    required
                    fullWidth
                    id='username'
                    label='用户账号'
                    value={values.username}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Select
                    variant='outlined'
                    required
                    fullWidth
                    label='用户角色'
                    name='role'
                    value={values.role}
                    onChange={handleInputChange}>
                    <MenuItem value={1}>会计</MenuItem>
                    <MenuItem value={2}>运营</MenuItem>
                    <MenuItem value={3}>售后</MenuItem>
                    <MenuItem value={4}>售前</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type='submit'
                    size='large'
                    fullWidth
                    variant='contained'
                    color='inherit'
                    className={classes.button}
                    onClick={enable}>
                    激活用户
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
