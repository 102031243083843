import config from './config';
import { openFile } from './util';
import DoneIcon from '@material-ui/icons/DoneAll';
import React from 'react';
import {
  Button,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  ImageField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useInput,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

const ItemFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label='搜索' source='q' alwaysOn />
    <ReferenceInput
      source='shop_id'
      reference='Shops'
      label='店铺'
      allowEmpty
      alwaysOn
      emptyValue={0}>
      <SelectInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput
      source='pay_method'
      reference='Payments'
      label='支付方式'
      allowEmpty
      alwaysOn
      emptyValue={0}>
      <SelectInput optionText='name' />
    </ReferenceInput>
    <SelectInput
      source='status'
      label='状态'
      fullWidth
      alwaysOn
      emptyValue={9}
      choices={[
        { id: 0, name: '待审核' },
        { id: 1, name: '已审核' },
        { id: 2, name: '已转账' },
      ]}
    />
  </Filter>
);

const ItemPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 200]} {...props} />
);

const ViewField = (props: any) => {
  const { label, record, source } = props;

  const disabled = !record[source];

  return (
    <div
      onClick={() => {
        window.open(record[source]);
      }}>
      <ImageField source={source} record={record} />
    </div>
  );
};

const ApproveButton = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Button
      onClick={async () => {
        const { record } = props;

        const token = localStorage.getItem('auth');

        const request = new Request(`${config.api}/RebateItems/${record.id}`, {
          method: 'PATCH',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
          }),
        });

        try {
          const response = await fetch(request);
          const body = await response.json();
          if (response.status < 200 || response.status >= 300) {
            throw new Error(body.message);
          }
          refresh();
          notify('更新信息成功');
        } catch (e) {
          notify(e.message, 'error');
        }
      }}
      label='批准'>
      <DoneIcon />
    </Button>
  );
};

export const RebateItemList = (props: any) => {
  return (
    <List
      {...props}
      filters={<ItemFilter />}
      filterDefaultValues={{ shop_id: 0, pay_method: 0, status: 9 }}
      perPage={25}
      pagination={<ItemPagination />}>
      <Datagrid>
        <TextField source='shops_name' label='店铺' />
        <TextField source='order_serial' label='订单号' />
        <DateField source='datetime' label='时间' />
        <TextField source='pay_method_name' label='支付方式' />
        <ViewField source='img_url' label='晒单' />
        <TextField source='comment' label='备注' />
        <NumberField source='money' label='金额' />
        <SelectField
          source='status'
          label='状态'
          fullWidth
          choices={[
            { id: 0, name: '待审核' },
            { id: 1, name: '已审核' },
            { id: 2, name: '已转账' },
          ]}
        />
        <ViewField source='pay_url' label='收款码' />
        <TextField source='users_name' label='操作员' />
        <ApproveButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const ImageUploader = (props: any) => {
  const { label } = props;

  const { input } = useInput(props);
  const notify = useNotify();

  const src = !input.value
    ? `${process.env.PUBLIC_URL}/upload.png`
    : input.value;

  return (
    <Labeled label={label}>
      <img
        alt={label}
        src={src}
        onClick={() =>
          openFile('image/*', async (file: File) => {
            const token = localStorage.getItem('auth');

            const body = new FormData();
            body.append('file', file);

            const request = new Request(`${config.api}/upload`, {
              method: 'POST',
              headers: new Headers({ Authorization: `Bearer ${token}` }),
              body,
            });

            try {
              const response = await fetch(request);
              const body = await response.json();

              if (response.status < 200 || response.status >= 300) {
                throw new Error(body.message);
              }

              input.value = body.data;
              input.onChange(input.value);
            } catch (e) {
              notify(e.message, 'error');
            }
          })
        }
      />
    </Labeled>
  );
};

export const RebateItemEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source='shops_name' label='店铺' fullWidth />
        <TextField source='order_serial' label='订单号' fullWidth />
        <DateField source='datetime' label='时间' fullWidth />
        <ReferenceField
          source='pay_method'
          reference='Payments'
          label='支付方式'
          fullWidth>
          <TextField source='name' />
        </ReferenceField>
        <ImageUploader source='img_url' label='晒单' />
        <TextInput source='comment' label='备注' fullWidth />
        <NumberInput source='money' label='金额' fullWidth isRequired />
        <SelectField
          source='status'
          label='状态'
          fullWidth
          choices={[
            { id: 0, name: '待审核' },
            { id: 1, name: '已审核' },
            { id: 2, name: '已转账' },
          ]}
        />
        <ImageUploader source='pay_url' label='转账凭证' />
      </SimpleForm>
    </Edit>
  );
};

export const RebateItemCreate = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('改动已保存');
    redirect('/RebateItems');
    refresh();
  };

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <ReferenceInput
          source='shop_id'
          reference='Shops'
          label='店铺'
          fullWidth
          isRequired>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <TextInput source='order_serial' label='订单号' fullWidth isRequired />
        <ReferenceInput
          source='pay_method'
          reference='Payments'
          label='支付方式'
          fullWidth
          isRequired>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <ImageUploader source='img_url' label='晒单' />
        <TextInput source='comment' label='备注' fullWidth />
        <NumberInput source='money' label='金额' isRequired fullWidth />
        <ImageUploader source='pay_url' label='收款码' />
      </SimpleForm>
    </Create>
  );
};
