import authProvider from './authProvider';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    open: false,
    error: '',
    name: '',
    phone: '',
    username: '',
    password: '',
    role: 1,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'username') {
      if (!/^[a-z][a-z0-9]*$/gi.test(value)) {
        return;
      }
    }
    setValues({ ...values, [name]: value });
  };

  const register = async (e: any) => {
    e.preventDefault();
    try {
      await authProvider.signup(values);
      window.location.hash = '/login';
    } catch (e) {
      setValues({ ...values, open: true, error: e.message });
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Snackbar
        open={values.open}
        autoHideDuration={6000}
        onClose={() => setValues({ ...values, open: false })}>
        <Alert
          severity='error'
          onClose={() => setValues({ ...values, open: false })}>
          {values.error}
        </Alert>
      </Snackbar>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          用户注册
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name='name'
                variant='outlined'
                required
                fullWidth
                id='name'
                label='姓名'
                autoFocus
                value={values.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='phone'
                variant='outlined'
                required
                fullWidth
                id='phone'
                label='手机'
                value={values.phone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='username'
                variant='outlined'
                required
                fullWidth
                id='username'
                label='账号'
                value={values.username}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                name='password'
                label='密码'
                type='password'
                id='password'
                value={values.password}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                variant='outlined'
                required
                fullWidth
                name='role'
                value={values.role}
                onChange={handleInputChange}>
                <MenuItem value={1}>会计</MenuItem>
                <MenuItem value={2}>运营</MenuItem>
                <MenuItem value={3}>售后</MenuItem>
                <MenuItem value={4}>售前</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            onClick={register}>
            注册
          </Button>
          <Link href='#/login'>登录</Link>
        </form>
      </div>
    </Container>
  );
}
