export const openFile = function (
  accept: string,
  callback: (file: File) => any
) {
  const inputElement = document.createElement('input');
  inputElement.type = 'file';
  inputElement.accept = accept;
  inputElement.addEventListener('change', function (e: Event) {
    if (this.files == null) {
      return;
    }
    callback(this.files[0]);
  });
  inputElement.dispatchEvent(new MouseEvent('click'));
};
