import config from './config';
import DoneIcon from '@material-ui/icons/DoneAll';
import React from 'react';
import {
  Button,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  List,
  Pagination,
  ReferenceInput,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

const ItemFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label='搜索' source='q' alwaysOn />
    <ReferenceInput
      source='shop_id'
      reference='Shops'
      label='店铺'
      allowEmpty
      alwaysOn
      emptyValue={0}>
      <SelectInput optionText='name' />
    </ReferenceInput>
    <SelectInput
      source='type_id'
      label='类型'
      fullWidth
      alwaysOn
      emptyValue={0}
      choices={[
        { id: 1, name: '改地址' },
        { id: 2, name: '退货' },
        { id: 3, name: '换货' },
      ]}
    />
    <SelectInput
      source='reason_id'
      label='原因'
      fullWidth
      alwaysOn
      emptyValue={0}
      choices={[
        { id: 1, name: '改地址' },
        { id: 2, name: '无理由' },
        { id: 3, name: '质量问题' },
        { id: 4, name: '货到件损' },
      ]}
    />
    <SelectInput
      source='status'
      label='状态'
      fullWidth
      alwaysOn
      emptyValue={9}
      choices={[
        { id: 0, name: '待处理' },
        { id: 1, name: '处理中' },
        { id: 2, name: '已处理' },
      ]}
    />
  </Filter>
);

const ItemPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 200]} {...props} />
);

const ApproveButton = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Button
      onClick={async () => {
        const { record } = props;
        if (record.status == 2) {
          notify('已处理结束', 'error');
          return;
        }
        const token = localStorage.getItem('auth');

        const request = new Request(`${config.api}/MiscItems/${record.id}`, {
          method: 'PATCH',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({ status: record.status + 1 }),
        });

        try {
          const response = await fetch(request);
          const body = await response.json();
          if (response.status < 200 || response.status >= 300) {
            throw new Error(body.message);
          }
          refresh();
          notify('更新信息成功');
        } catch (e) {
          notify(e.message, 'error');
        }
      }}
      label='处理'>
      <DoneIcon />
    </Button>
  );
};

export const MiscItemList = (props: any) => {
  return (
    <List
      {...props}
      filters={<ItemFilter />}
      filterDefaultValues={{ shop_id: 0, type_id: 0, reason_id: 0, status: 9 }}
      perPage={25}
      pagination={<ItemPagination />}>
      <Datagrid>
        <TextField source='shop_name' label='店铺' />
        <TextField source='order_serial' label='订单号' />
        <DateField source='datetime' label='时间' />
        <TextField source='type' label='类型' />
        <TextField source='reason' label='原因' />
        <TextField source='content' label='问题描述' />
        <TextField source='result' label='处理进度' />
        <SelectField
          source='status'
          label='状态'
          fullWidth
          choices={[
            { id: 0, name: '待处理' },
            { id: 1, name: '处理中' },
            { id: 2, name: '已处理' },
          ]}
        />
        <TextField source='user_name' label='操作员' />
        <ApproveButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const MiscItemEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source='shop_name' label='店铺' fullWidth />
        <TextField source='order_serial' label='订单号' fullWidth />
        <DateField source='datetime' label='时间' fullWidth />
        <SelectField
          source='type_id'
          label='类型'
          fullWidth
          choices={[
            { id: 1, name: '改地址' },
            { id: 2, name: '退货' },
            { id: 3, name: '换货' },
          ]}
        />
        <SelectField
          source='reason_id'
          label='原因'
          fullWidth
          choices={[
            { id: 1, name: '改地址' },
            { id: 2, name: '无理由' },
            { id: 3, name: '质量问题' },
            { id: 4, name: '货到件损' },
          ]}
        />
        <TextInput source='content' label='问题描述' fullWidth />
        <TextInput source='result' label='处理进度' fullWidth />
        <TextInput source='name' label='客户' fullWidth />
        <TextInput source='phone' label='电话' fullWidth />
        <TextInput source='address' label='地址' fullWidth />
        <SelectField
          source='status'
          label='状态'
          fullWidth
          choices={[
            { id: 0, name: '待处理' },
            { id: 1, name: '处理中' },
            { id: 2, name: '已处理' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export const MiscItemCreate = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('改动已保存');
    redirect('/MiscItems');
    refresh();
  };

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <ReferenceInput
          source='shop_id'
          reference='Shops'
          label='店铺'
          fullWidth
          isRequired>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <TextInput source='order_serial' label='订单号' fullWidth isRequired />
        <SelectInput
          source='type_id'
          label='类型'
          fullWidth
          choices={[
            { id: 1, name: '改地址' },
            { id: 2, name: '退货' },
            { id: 3, name: '换货' },
          ]}
        />
        <SelectInput
          source='reason_id'
          label='原因'
          fullWidth
          choices={[
            { id: 1, name: '改地址' },
            { id: 2, name: '无理由' },
            { id: 3, name: '质量问题' },
            { id: 4, name: '货到件损' },
          ]}
        />
        <TextInput source='content' label='描述' fullWidth />
        <TextInput source='result' label='处理进度' fullWidth />
        <TextInput source='name' label='客户' fullWidth />
        <TextInput source='phone' label='电话' fullWidth />
        <TextInput source='address' label='地址' fullWidth />
      </SimpleForm>
    </Create>
  );
};
