import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

export const GoodsList = (props: any) => {
  return (
    <List {...props} pagination={null}>
      <Datagrid isRowSelectable={() => false}>
        <TextField source='id' label='编号' />
        <TextField source='name' label='名称' />
        <NumberField source='price' label='价格' />
        <BooleanField source='enable' label='启用' />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const GoodsEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source='id' label='编号' />
        <TextInput source='name' label='名称' fullWidth />
        <NumberInput source='price' label='价格' fullWidth />
        <BooleanInput source='enable' label='启用' fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export const GoodsCreate = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('改动已保存');
    redirect('/Goods_all');
    refresh();
  };

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput source='name' label='名称' fullWidth />
        <NumberInput source='price' label='价格' fullWidth />
        <BooleanInput source='enable' label='启用' fullWidth />
      </SimpleForm>
    </Create>
  );
};
