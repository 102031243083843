import config from './config';
import { User } from './models';
import decodeJwt from 'jwt-decode';

const send = async (request: Request) => {
  try {
    return await fetch(request);
  } catch (e) {
    throw new Error('Network error');
  }
};

const authProvider = {
  login: async (values: { username: string; password: string }) => {
    const request = new Request(`${config.api}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({
        username_or_phone: values.username,
        password: values.password,
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    const response = await send(request);
    const body = await response.json();
    if (response.status < 200 || response.status >= 300) {
      throw new Error(body.message);
    }
    localStorage.setItem('auth', body.data.token);
  },
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  signup: async (values: User) => {
    const request = new Request(`${config.api}/auth/signup`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    const response = await send(request);
    const body = await response.json();
    if (response.status < 200 || response.status >= 300) {
      throw new Error(body.message);
    }
  },
  checkError: (error: any) => {
    if (error.status === 401 || error.status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem('auth');
    if (token === null) return Promise.reject();

    const decoded: { exp: number } = decodeJwt(token);

    return decoded.exp > Date.now() / 1000
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    const token = localStorage.getItem('auth');
    if (token === null) return Promise.resolve('-1');

    const decoded: { role: number } = decodeJwt(token);

    return Promise.resolve(`${decoded.role}`);
  },
};

export default authProvider;
