import authProvider from './authProvider';
import config from './config';
import customRoutes from './customRoutes';
import {
  GiveawayItemList,
  GiveawayItemEdit,
  GiveawayItemCreate,
} from './giveawayItem';
import { MiscItemCreate, MiscItemEdit, MiscItemList } from './miscItem';
import { RebateItemList, RebateItemEdit, RebateItemCreate } from './rebateItem';
import Login from './login';
import restApiProvider from './restApiProvider';
import chineseMessages from './zh';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { GoodsList, GoodsEdit, GoodsCreate } from './goods';
import { Admin, Resource, fetchUtils } from 'react-admin';
import MyLayout from './layout';

const i18nProvider = polyglotI18nProvider(() => chineseMessages, 'ch');

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('auth');
  //@ts-ignore
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = restApiProvider(config.api, httpClient);

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    customRoutes={customRoutes}
    layout={MyLayout}
    loginPage={Login}>
    {permissions => [
      permissions >= '0' ? (
        <Resource
          options={{ label: '赠品' }}
          name='GiveawayItems'
          list={GiveawayItemList}
          edit={GiveawayItemEdit}
          create={GiveawayItemCreate}
        />
      ) : undefined,
      permissions >= '0' ? (
        <Resource
          options={{ label: '返现' }}
          name='RebateItems'
          list={RebateItemList}
          edit={RebateItemEdit}
          create={RebateItemCreate}
        />
      ) : undefined,
      permissions >= '0' ? (
        <Resource
          options={{ label: '事务' }}
          name='MiscItems'
          list={MiscItemList}
          edit={MiscItemEdit}
          create={MiscItemCreate}
        />
      ) : undefined,

      permissions === '0' ? (
        <Resource
          options={{ label: '赠品管理' }}
          name='Goods_all'
          list={GoodsList}
          edit={GoodsEdit}
          create={GoodsCreate}
        />
      ) : undefined,

      <Resource name='Goods' />,
      <Resource name='Shops' />,
      <Resource name='Payments' />,
    ]}
  </Admin>
);

export default App;
