import ExportPage from './export';
import SignupPage from './signup';
import { Route } from 'react-router-dom';

const customRoutes = [
  //@ts-ignore
  <Route exact path='/signup' component={SignupPage} noLayout />,
  <Route exact path='/export' component={ExportPage} />,
];

export default customRoutes;
